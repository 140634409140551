





import { useState, 
         useEffect }   from 'react' ;
import { useNavigate } from 'react-router-dom';
import   Axios         from 'axios';
 
export default function useAuth(code, origin, spotifyApi, setPremium) {

  const [accessToken,  setAccessToken ]     = useState();
  const [refreshToken, setRefreshToken]     = useState();
  const [expiresIn,    setExpiresIn   ]     = useState();

  const navigate = useNavigate();


  useEffect(() => {     
      
        if (!code) { return; }

        // Axios.post('http://localhost:3001/login', { code }
        Axios.post('https://mprecords.herokuapp.com/login', { code }

            ).then(res => {


                setAccessToken(res.data.accessToken);
               setRefreshToken(res.data.refreshToken);
                  setExpiresIn(res.data.expiresIn);
                      navigate(origin);
                
                spotifyApi.getMe()
                .then(function(data) {
                    
                    let displayName = data.body.display_name;
                    let email       = data.body.email;
                    let country     = data.body.country;
                    let followers   = data.body.followers.total;
                    let product     = data.body.product;

                    if (product === 'premium') { setPremium(true) };
                    
                    // Axios.post('http://localhost:3001/logUser', { displayName, email, country, followers }
                    Axios.post('https://mprecords.herokuapp.com/logUser', { displayName, email, country, followers }

                    ).then(res => { console.log('successful log in')            }
                    ).catch(() => { console.log('data-entry error'); } )
                    
                }, function(err) {
                    console.log('Something went wrong!', err);
                });
                
                
                        
            }).catch(() => {
                console.log('There was a problem logging in...');
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [code])  
 
    useEffect(() => {

        if (!refreshToken || !expiresIn)    { return; }

        const interval = setInterval(() =>  {
                                             // Axios.post('http://localhost:3001/refresh', { refreshToken, }
                                                Axios.post('https://mprecords.herokuapp.com/refresh', { refreshToken, }
                                                    ).then(res => {
                                                                    console.log(res.data);
                                                                    setAccessToken(res.data.accessToken);
                                                                    setExpiresIn(res.data.expiresIn);
                                                                    }
                                                    ).catch(() => { window.location = "/" })
                                            }, (expiresIn - 60) * 1000)

        return () => clearInterval(interval);

    }, [refreshToken, expiresIn])

    return accessToken;
}  

