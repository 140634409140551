





import './Loader.css';



export default function Loader() {

    return (
        <div id='loaderWrapper'>
            <div id='loader'/>
            <h2>Loading...</h2>
        </div>
    )
}