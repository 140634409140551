





import   React, 
       { useState, 
         useEffect }   from 'react' 
import   SpotifyPlayer from 'react-spotify-web-playback'


export default function Player({accessToken, trackUri}) {

    const [play, setPlay] = useState(false);

    useEffect(() =>  setPlay(true),  [trackUri])

    if (!accessToken) { return null; }

    return(
        <div id='playerWrapper'>
            <SpotifyPlayer 
                token={accessToken}
                showSaveIcon
                callback={state => {
                    if (!state.isPlaying) setPlay(false);
                }}
                play={play}
                uris={trackUri ? [trackUri] : []}
                styles={{
                    activeColor: 'rgba(207, 161, 83)',
                    bgColor: 'rgba(1, 1, 1, 0)',
                    color: '#fff',
                    loaderColor: '#fff',
                    sliderColor: 'rgba(207, 161, 83)',
                    trackArtistColor: 'rgba(207, 161, 83)',
                    trackNameColor: '#fff',
                  }}
            />
        </div>
    )
}  