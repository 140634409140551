





import                     './Footer.css';
import   React        from 'react';
import { Link }       from 'react-router-dom';
import   Player       from './Player';

import iconReleases   from '../images/icons/icon_releases.png';
import iconArtists    from '../images/icons/icon_artists.png';
import iconInfo       from '../images/icons/icon_info.png';
import iconContact    from '../images/icons/icon_contact.png';
import iconDemos      from '../images/icons/icon_demos.png';
import iconBookings   from '../images/icons/icon_bookings.png';





export default function Footer({ playingTrack, token, setSubject, premium }) {




    
    const noSubject       = () => { setSubject('') };    
    const bookingsSubject = () => { setSubject('Artist Booking Inquiry'); };

    const footerLink      = ( path, title, icon, onClick, notForMobile )  => {
        return (
            <li>
                <Link to={path} onClick= {onClick} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                    <div      className= {notForMobile ? 'footerLink notForMobile' : 'footerLink' } >
                        <img  className='flIcon' alt='an icon' src={icon} />
                        <p    className='flCaption'>{title}</p>
                    </div>
                </Link>
            </li>
        )
    }
    

    return(
        <>
            <div id='playerWrapper'>
                { token && premium && <Player id='player' accessToken={token} trackUri={playingTrack?.uri}  /> }
            </div>

            <ul id='footerLinks' > 
                {  footerLink('/artists',              'artists',    iconArtists)                              }
                {  footerLink('/releases',             'releases',   iconReleases)                             }
                {  footerLink('/info',                 'info',       iconInfo)                                 }
                {  footerLink('/contact#pageTop',      'contact',    iconContact,    noSubject)                }
                {  footerLink('/contact#demoForm',     'demos',      iconDemos,      null,              true)  }
                {  footerLink('/contact#emailForm',    'bookings',   iconBookings,   bookingsSubject,   true)  }
            </ul>
        </>
    )
}  


