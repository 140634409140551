





import iconInstagram    from '../images/icons/icon_instagram.png';
import iconFacebook     from '../images/icons/icon_facebook.png';
import iconSoundCloud   from '../images/icons/icon_soundcloud.png';
import iconYouTube      from '../images/icons/icon_youtube.png';
import iconAppleMusic   from '../images/icons/icon_applemusic.png';
import iconSpotify      from '../images/icons/icon_spotify.png';



export default function PlatformLinks({listClass, iconClass, noSpotify, nonSpotify}) {


    function platformLink (platform, url, img, spotifyAlternative) {

        function thisClass () {

                 if ( !spotifyAlternative && nonSpotify ) { return iconClass + ' hidden';  }
            else if (  spotifyAlternative && noSpotify  ) { return iconClass + ' glowing'; }
            else                                          { return iconClass;              }
        }     

        return (
                <li>
                    <a href={url}
                       target="_blank"
                       rel="noreferrer"
                    >
                        <img       alt={platform + ' icon'}   
                             className={thisClass()}
                                   src={img} 
                        />
                    </a>
                </li>
        )
    }

    

    return(
        <>
            <ul className={listClass} >
                
                    {  platformLink(  'Instagram',   
                                      'https://www.instagram.com/mortarandpestlerec/',
                                      iconInstagram,

                                   )    
                    }
                    {  platformLink(  'Facebook',    
                                      'https://www.facebook.com/MortarAndPestleRecords',
                                      iconFacebook
                                   )
                    }
                    {  platformLink(  'SoundCloud',
                                      'https://soundcloud.com/mortarandpestlerecords',
                                      iconSoundCloud,
                                      true
                                   )   
                    }
                    {  platformLink(  'YouTube',     
                                      'https://www.youtube.com/channel/UCbvxkzmorm4dVplO0cNaO5A',
                                      iconYouTube,
                                      true
                                   )
                    }
                    {  platformLink(  'Apple Music',
                                      'https://music.apple.com/us/album/heart-n-soul-ep/1561492549',
                                      iconAppleMusic,
                                      true
                                   )
                    } 
                    {  platformLink(  'Spotify',
                                      'https://open.spotify.com/user/skenemusic/playlist/3SzMFnsvEDyIbkhrtMGyW7?si=AKL-w7dqTfqySfUbOVugMg',
                                      iconSpotify
                                   )      
                    }
                
            </ul>
                
        </>
    )
}  