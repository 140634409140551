





import                         './App.css';
import { useState, 
         useEffect,
         useRef,
         lazy,
         Suspense      }   from 'react';
import { Routes,
         Route,
         useLocation,
         Link          }   from 'react-router-dom';
// import { Helmet }          from 'react-helmet';
import   SpotifyWebApi     from 'spotify-web-api-node';
import   Axios             from 'axios';

import   djAngel           from './images/margin_DJangel.webp';
import   djMary            from './images/margin_DJvirginMary.webp';
import   moon              from './images/margin_moon.webp';
import   smokingAngel      from './images/margin_smokingAngel.webp';
import   logoGold          from './images/logo_gold.webp';

import   djAngelPng       from './images/margin_DJangel.png';
import   djMaryPng        from './images/margin_DJvirginMary.png';
import   moonPng          from './images/margin_moon.png';
import   smokingAngelPng  from './images/margin_smokingAngel.png';
import   logoGoldPng      from './images/logo_gold.png';

import   useAuth          from './useAuth';

import   Loader           from './Components/Loader.js';
import   Footer           from './Components/Footer';
import   Header           from './Components/Header';
import   Picture          from './Components/Picture.js';



const EventFlyer= lazy(() => import('./Components/EventFlyer.js'));
const Home      = lazy(() => import('./Pages/Home'));
const Artists   = lazy(() => import('./Pages/Artists'));
const Releases  = lazy(() => import('./Pages/Releases'));
const Info      = lazy(() => import('./Pages/Info'));
const Contact   = lazy(() => import('./Pages/Contact'));


const    spotifyApi        = new   SpotifyWebApi({ clientId : '3ac43350aae7432abdadbafced0dd5b6', });





function App() {

  const [ playingTrack,  setPlayingTrack ]   = useState();
  const [ token,         setToken        ]   = useState();
  const [ artist,        setArtist       ]   = useState(0);
  const [ subject,       setSubject      ]   = useState('');
  const [ premium,       setPremium      ]   = useState(false);
  const [ artistData,    setArtistData   ]   = useState([]);
  const [ roster,        setRoster       ]   = useState([]);
  const [ askem,         setAskem        ]   = useState([]);
  const [ noSpotify,     setNoSpotify    ]   = useState(false);
  const [ eventData,     setEventData    ]   = useState([]);
  const [ partyComing,   setPartyComing  ]   = useState(false);
  const [ shimmering,    setShimmering   ]   = useState(false);


  const topRef                               = useRef(null);


  const location                             = useLocation();

  const code                                 = new URLSearchParams(window.location.search).get('code'); 
  const state                                = new URLSearchParams(window.location.search).get('state'); 

  const accessToken                          = useAuth(code, state, spotifyApi, setPremium);

  
  
  useEffect(() => {

    // Axios.get('http://localhost:3001/get_data').then(res => {
    Axios.get('https://mprecords.herokuapp.com/get_data').then(res => {

      let data     = res.data;
      let asks     = data.artistData[0].slice(3);
      let facts    = data.artistData.slice(1);
      let team     = facts.map((artist, index) => { return { id: index, name: artist[0] } });
      let events   = data.eventData.slice(1);

      setArtistData(facts);
      setRoster(team);
      setAskem(asks);
      setEventData(events);
  })

  }, [])


  useEffect(() => {

    if (!accessToken) { return; }

    spotifyApi.setAccessToken(accessToken);
    setToken(accessToken);

  }, [accessToken])


  useEffect(() => {

    // eventData.length !== 0 && setTimeout(() => setPartyComing(true), 1000);    
    eventData.length !== 0 && setPartyComing(true);    


  }, [eventData]);


  useEffect(() => {

    partyComing ? window.onscroll = () => { window.scroll(0, 0); }
                : window.onscroll = null;

  }, [partyComing]);


  useEffect(() => {

    if (eventData.length !== 0 && !partyComing) {

      setInterval(  () => { setShimmering(true);
                            setTimeout( () => setShimmering(false), 1000);
                          }, 20000);
    }
    return setShimmering(false);

  }, [eventData, partyComing])




  return (
    <>
        { partyComing && <Suspense fallback={<Loader />} >
                            <div id='popupFlyer'>
                              <h2 id='popupEscape' onClick={() => setPartyComing(false)}>X</h2>
                              < EventFlyer event={eventData[0]} />
                            </div>
                         </Suspense>
        }


      <div id="header" >
          <Header     setArtist={setArtist}
                         roster={roster}
                      noSpotify={noSpotify}
          />
      </div>


      <div id="body" >

        <div id="bodyBackground">
          <Routes>
            <Route path="/artists"    element={<Picture className='backgroundCartoon' id='bgiLL' alt='an angel DJ holding a record'         src={djAngel}      fallback={djAngelPng}      />} />  
            <Route path="/releases"   element={<Picture className='backgroundCartoon' id='bgiLR' alt='DJ Virgin Mary holding a turntable'   src={djMary}       fallback={djMaryPng}       />} /> 
            <Route path="/info"       element={<Picture className='backgroundCartoon' id='bgiUL' alt='a smiling moomn'                      src={moon}         fallback={moonPng}         />} /> 
            <Route path="/contact"    element={<Picture className='backgroundCartoon' id='bgiUR' alt='a smoking angel with a glass of wine' src={smokingAngel} fallback={smokingAngelPng} />} /> 
          </Routes>
        </div>
        
        <div id="main" ref={topRef} className="container">
          
          <Routes>
                  <Route path="/" element={<Suspense fallback={<Loader />} >
                                              <Home                      code={code}      
                                                                  accessToken={accessToken}
                                                                      premium={premium} 

                                              />
                                            </Suspense>} 
                  />         
                  
                  <Route path="/artists" element={<Suspense fallback={<Loader />}>
                                                    <Artists            code={code} 
                                                            setPlayingTrack={setPlayingTrack} 
                                                                      token={token} 
                                                                      artist={artist} 
                                                                  spotifyApi={spotifyApi}
                                                                    premium={premium}
                                                                  setArtist={setArtist}
                                                                  artistData={artistData}
                                                                      askem={askem}
                                                                  noSpotify={noSpotify}
                                                                setNoSpotify={setNoSpotify}
                                                    />
                                                  </Suspense>} 
                  />
                  <Route path="/releases" element={<Suspense fallback={<Loader />}>
                                                    <Releases         code={code} 
                                                           setPlayingTrack={setPlayingTrack} 
                                                                     token={token}
                                                                spotifyApi={spotifyApi}
                                                              playingTrack={playingTrack}
                                                                   premium={premium}
                                                                 noSpotify={noSpotify}
                                                              setNoSpotify={setNoSpotify}
                                                                    roster={roster}
                                                    />
                                                  </Suspense>}
                  />         
                  <Route path="/info"     element={<Suspense fallback={<Loader />}>
                                                      <Info        eventData={eventData} />
                                                   </Suspense>}
                  />        
                   
                  <Route path="/contact"  element={<Suspense fallback={<Loader />}> 
                                                      <Contact        topRef={topRef}
                                                                   subject={subject} 
                                                                setSubject={setSubject}                      
                                                      />
                                                    </Suspense>} 
                  />        
          </Routes>

          <div id='bottomLogoWrapper'>
            { location.pathname !== '/' &&  <Picture id='bottomLogo' src={logoGold} fallBack={logoGoldPng} alt="Mortar & Pestle Records logo" /> }
          </div>

        </div>
      </div>
  

      <div id="footer" >
        { (eventData.length !== 0 && !partyComing) && <Link to='/info#eventFlyers'>
                                                        <div id='eventLinksLink' 
                                                            className={shimmering && 'shimmer'}

                                                        >
                                                          <h5>Event Links</h5>
                                                        </div>
                                                      </Link>
        }
        <div className="container">
          <Footer           code={code} 
                         premium={premium}
                    playingTrack={playingTrack} 
                           token={token} 
                      setSubject={setSubject} 
          />
        </div>
      </div>

     
    
    



    </>
  );
}





export default App;
