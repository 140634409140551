





import    React, 
        { useState, 
          useEffect, 
          useRef        } from 'react';
import                         './Dropdown.css';

export default function Dropdown({setArtist, roster}) {



    const [showItems,    setShowItems]    = useState(false);
    const [selectedItem, setSelectedItem] = useState(0);

    const  dropdownRef                    = useRef(null);

    
    useEffect(() => {

        setArtist(selectedItem);
                                
    });
        
    useEffect(() => {

        document.addEventListener("mousedown", handleClickOutside);
    
        return () => { document.removeEventListener("mousedown", handleClickOutside); }

    });


    const dropDown = () => {

        setShowItems(showing => !showing);
    };

    const selectItem = item => {

        setSelectedItem(item.id);
        setShowItems(false);
    };

    const handleClickOutside = (event) => {

        if (  dropdownRef &&
             !dropdownRef.current.contains(event.target) ) 
           {  setShowItems(false);                       }
    };



    return (

        <div ref={dropdownRef} className='dropdown'>
            <div className='dropdownBox'>
                <div className='dropdownSelectedItem'>
                    { roster.length !== 0 && roster[selectedItem].name }
                </div>
                <div className='dropdownArrowWrapper' onClick={dropDown}>
                    <span
                        className={`${ showItems ? 'dropdownArrow up'
                                                 : 'dropdownArrow down'
                                    }`}
                    />
                </div>
            </div>
            <div
                style={{ display: showItems ? 'block' : 'none' }}
                className='dropdownItems'
            >
                { roster.length !== 0 && roster.map(item => (
                                                                <div
                                                                    key={item.id}
                                                                    onClick={ () => selectItem(item) }
                                                                    className={ selectedItem === item && 'selected' }
                                                                >
                                                                    {item.name}
                                                                </div>
                                                            )
                                                    )
                }
            </div>
        </div>
    );
}
